<template>
  <section class="img-wrap ratio-75">
    <a :href="slide.url" :title="slide.name" target="_blank">
      <div class="img-content">
        <!-- {{ slide }} -->

        <b-img
          class="gray-filter"
          :src="defaultPathLogo"
          @mouseover="defaultPathLogo = slide.logo && slide.logo.path"
          @mouseleave="
            defaultPathLogo = slide.grey_logo && slide.grey_logo.path
          "
          fluid
        ></b-img>
      </div>
    </a>
  </section>
</template>

<script>
export default {
  props: {
    slide: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      defaultPathLogo: this.slide.grey_logo && this.slide.grey_logo.path
    };
  }
};
</script>
